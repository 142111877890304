var mapboxAccessToken = 'pk.eyJ1IjoiZ2VyZ28iLCJhIjoiY2p2bGU0NnFjMHljOTN6bWcxb2hxM2FoYSJ9.ANF8oKFwcbyc0r33XGoh1w';
var map = L.map('map').setView([47.4, 19.58], 7);

map.attributionControl.addAttribution('Map data © <a href="https://www.openstreetmap.org" title="OpenStreetMap">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/3.0/" title="CC-BY-SA">CC-BY-SA</a>');
map.attributionControl.addAttribution('Imagery © <a href="https://www.mapbox.com/" title="Mapbox">Mapbox</a>');
map.attributionControl.addAttribution('Population data © <a href="http://www.ksh.hu/?lang=en" title="Hungarian Central Statistical Bureau">Hungarian Central Statistical Bureau</a>');

L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=' + mapboxAccessToken, {
    id: 'mapbox.light',
    attribution: ""
}).addTo(map);

function loadJSON(callback) {   
    var xobj = new XMLHttpRequest();
        xobj.overrideMimeType("application/json");
    xobj.open('GET', 'magyar_megyek.geojson', true);
    xobj.onreadystatechange = function () {
          if (xobj.readyState == 4 && xobj.status == "200") {
            // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
            callback(xobj.responseText);
          }
    };
    xobj.send(null);
}

var statesJSON;
var geojson;

function init() {
    loadJSON(function(response) {
    statesJSON = JSON.parse(response);
    
    geojson = L.geoJson(statesJSON, {
        style: style,
        onEachFeature: onEachFeature
    }).addTo(map);
    });
}

init();

// colouring as per density
function getColor(d) {
    return d > 200  ? '#7f2704' :
           d > 150  ? '#a63603' :
           d > 125  ? '#d94801' :
           d > 100  ? '#f16913' :
           d > 90   ? '#fd8d3c' :
           d > 80   ? '#fdae6b' :
           d > 70   ? '#fdd0a2' :
           d > 60   ? '#fee6ce' :
                      '#fff5eb';
}

// default styling
function style(feature) {
    return {
        fillColor: getColor(feature.properties.density),
        weight: 2,
        opacity: 1,
        color: 'white',
        dashArray: '3',
        fillOpacity: 0.7
    };
}

// we are setting this for mouseOver
function highlightFeature(e) {
    var layer = e.target;

    layer.setStyle({
        weight: 4,
        color: '#aaa',
        dashArray: '',
        fillOpacity: 0.7
    });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
    }
    info.update(layer.feature.properties);
}

// style for mouseOut
function resetHighlight(e) {
    geojson.resetStyle(e.target);
    info.update();
}

// style when clicked
function zoomToFeature(e) {
    map.fitBounds(e.target.getBounds());
}

function onEachFeature(feature, layer) {
    layer.on({
        mouseover: highlightFeature,
        mouseout: resetHighlight,
        click: zoomToFeature
    });
}

// info panels
var info = L.control();

info.onAdd = function (map) {
    this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
    this.update();
    return this._div;
};

// method that we will use to update the control based on feature properties passed
info.update = function (props) {
    this._div.innerHTML = '<h4>Hungarian Population Density per County</h4>' +  (props ?
        '<b>' + props.name + '</b><br />' + props.density + ' people / km<sup>2</sup>'
        : 'Hover over a county');
};

info.addTo(map);

// custom legend
var legend = L.control({position: 'bottomright'});

legend.onAdd = function (map) {

    var div = L.DomUtil.create('div', 'info legend'),
        grades = [0, 60, 70, 80, 90, 100, 125, 150, 200],
        labels = [];

    // loop through our density intervals and generate a label with a colored square for each interval
    for (var i = 0; i < grades.length; i++) {
        div.innerHTML +=
            '<i style="background:' + getColor(grades[i] + 1) + '"></i> ' +
            grades[i] + (grades[i + 1] ? '&ndash;' + grades[i + 1] + '<br>' : '+');
    }

    return div;
};

legend.addTo(map);
